import React, { useState, useEffect } from 'react';
import { Button, Input, Icon, Fieldset, HeroTitle, HeroNumber } from '@cof/gravity-react';
import ProgressBar from '../ProgressBar/ProgressBar';

import { formatDepositDueDate } from '../../utils/formatDueDate';
import { general, depositAmountForm } from '../../strings';

import './DepositAmountForm.scss';

const numberRegex = /^[0-9]*$/;

const maskDepositAmountInput = (e, setFunction) => numberRegex.test(e.target.value) && setFunction(e.target.value);

const DepositAmountForm = ({ activeLanguage, potomacLoaded, pageData, advancePage, showDepositHistoryDialog, showDepositCreditLineDialog }) => {
  document.title = general[activeLanguage].DepositNow;

  const srn = pageData.srn;
  useEffect(() => {
    if (potomacLoaded) {
      console.log('Sending pageView event for Deposit Amount Form');
      window.sp(
        'trackPageView',
        'v2 - Deposit Amount Form',
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [potomacLoaded, srn]);

  const logError = (errorMessage, payload = {}) => {
    const err = new Error(errorMessage);
    if (window?.newrelic) {
      window.newrelic.noticeError(err, payload);
      return;
    }

    // If the application is running locally or new relic is, for some reason, undefined, simply
    // log the error to the console.
    console.error(err, payload);
  };

  const [depositAmount, setDepositAmount] = useState(pageData.depositAmount || '');

  const depositRequiredAmount = pageData.minimumDepositAmount;
  const minCreditLine = pageData.minimumCreditLineAmount;
  const maxCreditLine = pageData.maximumCreditLineAmount;
  const dueDate = formatDepositDueDate(pageData.depositDueDate);
  const depositHistoryTotal = pageData?.depositHistoryTotal;

  if (depositHistoryTotal == null) {
    logError('Missing deposit history total', { message: `Application ${srn} is missing the deposit history total` });
  }

  const maxDeposit = pageData.maximumDepositAmount;
  const maxAllowableDeposit = maxDeposit - depositHistoryTotal;

  const depositAmountTooSmallError = depositAmount < 20 && depositAmountForm[activeLanguage].AmountToDepositInvalid20;
  const depositAmountTooLargeError = depositAmount > maxAllowableDeposit && `${depositAmountForm[activeLanguage].AmountToDepositInvalidMax}${maxAllowableDeposit}`;

  const canGoToNextPage = depositAmount !== '' && !(depositAmountTooLargeError || depositAmountTooSmallError);

  const creditLineHelper = `${depositAmountForm[activeLanguage].SliderOkMessage}$${depositHistoryTotal + (depositAmount * 1)}${depositAmountForm[activeLanguage].SliderOkMessage2}`;
  const newCreditLimit = `$${minCreditLine - depositRequiredAmount + depositHistoryTotal + (depositAmount * 1)}`;

  const creditLineEmptyHelper = `${depositAmountForm[activeLanguage].SliderNeedMessage}$${depositRequiredAmount}${depositAmountForm[activeLanguage].SliderNeedMessage2}${minCreditLine}`;

  return (
    <div className="sc-deposit-amount-form">
      <HeroTitle>{general[activeLanguage].DepositNow}</HeroTitle>
      <div className="deposit-info-row">
        <HeroNumber symbol="$" symbolPosition="start" value={depositRequiredAmount} label={depositAmountForm[activeLanguage].DepositRequired} size="small" />
        <div id="depositHistory" className="grv-hero-numbers__container" onClick={showDepositHistoryDialog}>
          <div className="grv-hero-numbers grv-hero-numbers--center grv-hero-numbers--small">
            <span className="grv-hero-numbers__symbol">$</span>
            <span className="grv-hero-numbers__amount">{depositHistoryTotal}</span>
          </div>
          <p className="grv-hero-numbers__label grv-hero-numbers__label--center grv-hero-numbers__label--small">{depositAmountForm[activeLanguage].DepositHistory}<Icon type="ArrowRightLined" /></p>
        </div>
        <div className="grv-hero-numbers__container">
          <div className="grv-hero-numbers grv-hero-numbers--small" style={{ display: 'block', textAlign: 'right' }}>
            <span className="grv-hero-numbers__symbol"></span>
            <span className="grv-hero-numbers__amount">{dueDate.toLocaleDateString(activeLanguage + '-us')}</span>
          </div>
          <p className="grv-hero-numbers__label grv-hero-numbers__label--small" style={{ float: 'right' }}>{depositAmountForm[activeLanguage].DueBy}</p>
        </div>
      </div>

      <ProgressBar stepNumber="one" />

      <Fieldset legend={depositAmountForm[activeLanguage].DepositCutoff}>
        <div id="depAmtInputGroup">
          <span id="depAmtPrefix">$</span>
          <span id="infoModalLink" onClick={() => showDepositCreditLineDialog(maxCreditLine, minCreditLine)}><Icon type="InformationLined"></Icon></span>
          <Input
            label={depositAmountForm[activeLanguage].AmountToDeposit}
            placeholder={`${depositRequiredAmount}`}
            value={depositAmount}
            error={depositAmount !== '' && (depositAmountTooSmallError || depositAmountTooLargeError)}
            helper={
              (depositAmount !== '' && !(depositAmountTooSmallError || depositAmountTooLargeError)) && (depositHistoryTotal + (depositAmount * 1) >= depositRequiredAmount) ?
                <span>{creditLineHelper}<span style={{color: 'green'}}>{newCreditLimit}</span>.</span> :
                creditLineEmptyHelper
            }
            id="deposit-amount-input"
            onChange={e => maskDepositAmountInput(e, setDepositAmount)}
            onEnter={_e => canGoToNextPage && advancePage({ depositAmount, depositHistoryTotal })}
          />
        </div>
        <div className="button-group">
          <Button constantWidth={false} type="progressive" disabled={!canGoToNextPage} onClick={_e => advancePage({ depositAmount, depositHistoryTotal })}>{general[activeLanguage].Next}</Button>
        </div>
      </Fieldset>
    </div>
  );
};

export default DepositAmountForm;
