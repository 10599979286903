export const general = {
  en: {
    DepositNow: 'Make a Deposit Now',
    DepositAmount: 'Deposit Amount',
    Continue: 'Continue',
    MakeDeposit: 'Make a Deposit',
    Back: 'Back',
    Next: 'Next',
    Close: 'Close',
    Agree: 'Agree',
    Submit: 'Submit',
    Print: 'Print this page for your records',
    Language: 'En Español',
    Privacy: 'PRIVACY',
    Security: 'SECURITY',
    TermsAndConditions: 'TERMS & CONDITIONS',
    EqualHousingLender: 'Equal Housing Lender',
    Cancel: 'Cancel',
  },
  es: {
    DepositNow: 'Hacer un Depósito Ahora',
    DepositAmount: 'Cantidad a Depositar',
    Continue: 'Continuar',
    MakeDeposit: 'Hacer un Depósito',
    Back: 'Regresar',
    Next: 'Siguiente',
    Close: 'Cerrar',
    Agree: 'Aceptar',
    Submit: 'Enviar',
    Print: 'Imprimir esta página para tus registros',
    Language: 'In English',
    Privacy: 'PRIVACIDAD',
    Security: 'SEGURIDAD',
    TermsAndConditions: 'TÉRMINOS Y CONDICIONES',
    EqualHousingLender: 'Prestamista de Viviendas',
    Cancel: 'Cancelar',

  },
};

export const errorMessages = {
  en: {
    CardActive: 'Sorry, it looks like you are no longer eligible to fund your Secured Card because you already activated your card.',
    GenericErrorMessage: 'Sorry, we cannot process your request at this time. Please try again later.',
    FundErrorMessage: 'Sorry, it looks like you are no longer eligible to fund your Secured Card. This could be because the deposit due date has already passed or because your application is no longer valid.',
    InvalidApplicant: 'Sorry, it looks like one or more of your credentials was not valid. Please try again.',
    SubmitApplicationFailed: 'Sorry, there was an issue submitting your deposit. This may just be a temporary system issue. Please call us at 1-800-219-7931 or try again later.',
    SubmitApplicationBadRequest: 'Sorry, it looks like there was an issue with your submission. Please review and verify your information before trying again.',
    SubmitApplicationInvalidSRN: 'Sorry, it looks like there was an issue finding your application. Please call us at 1-800-219-7931 or try again later.',
    DepositExceedMax: 'Sorry, you are no longer eligible to fund your Secured Card because you can only increase your credit line up to $',
    DepositConfirmationFailed: 'Something went wrong and we are unable to confirm your deposit went through.  Please check the Deposit History page or call 1-800-903-9177 to confirm the deposit was successful before trying again.',
    InvalidBankAccount: 'Sorry, it looks like your bank account is not valid.',
  },
  es: {
    CardActive: 'Lo lamentamos, parece que ya no calificas para depositar fondos en tu Tarjeta con Depósito de Garantía porque ya activaste tu tarjeta.',
    GenericErrorMessage: 'Lo lamentamos, no podemos tramitar tu petición en este momento. Por favor intenta de nuevo más tarde.',
    FundErrorMessage: 'Lo lamentamos, parece que ya no calificas para depositar fondos en tu Tarjeta con Depósito de Garantía. Esto puede ser debido a que la fecha de vencimiento del depósito ya pasó o a que tu solicitud ya no es válida.',
    InvalidApplicant: 'Lo lamentamos, parece que una o más de tus credenciales no son válidas. Por favor intentalo de nuevo.',
    SubmitApplicationFailed: 'Lo lamentamos, se presentó un problema al enviar tu depósito. Puede que solo sea un problema temporal con el sistema. Por favor llámanos al 1-800-219-7931 o inténtalo de nuevo más tarde.',
    SubmitApplicationBadRequest: 'Lo lamentamos, parece que se presentó un problema con tu envío. Por favor revisa y verifica tu información antes de intentarlo de nuevo.',
    SubmitApplicationInvalidSRN: 'Lo lamentamos, parece que se presentó un problema al buscar tu solicitud. Por favor llámanos al 1-800-219-7931 o inténtalo de nuevo más tarde.',
    DepositExceedMax: 'Lo lamentamos, parece que ya no calificas para depositar fondos en tu Tarjeta con Depósito de Garantía porque solo puedes aumentar tu línea de crédito hasta $',
    DepositConfirmationFailed: 'Algo salió mal y no nos es posible confirmar que tu depósito se tramitó. Por favor consulta la página de Historial de Depósitos o llama al 1-800-903-9177 para confirmar si el depósito se tramitó con éxito antes de intentar de nuevo.',
    InvalidBankAccount: 'Lo lamentamos, parece que tu cuenta bancaria no es válida.',
  },
};

export const agentFormStrings = {
  en: {
    AgentFormTitle: 'Hi There!',
    SubTitle: 'Please enter your EID to begin making a deposit on a Secured Card.',
    Eid: 'EID',
    AgentEidInvalid: 'Please enter your 6-character EID',
    ConfirmAgentEid: 'Re-enter EID',
    ConfirmAgentEidInvalid: 'Please confirm your 6-character EID',
  },
  es: {
    AgentFormTitle: '¡Hola!',
    SubTitle: 'Por favor ingresa tu identificación de empleado para comenzar a hacer un depósito a una Tarjeta con Depósito de Garantía.',
    Eid: 'Identificación de empleado',
    AgentEidInvalid: 'Por favor ingresa los 6 caracteres de tu identificación de empleado',
    ConfirmAgentEid: 'Vuelve a ingresar tu identificación de empleado',
    ConfirmAgentEidInvalid: 'Por favor confirma los 6 caracteres de tu identificación de empleado',
  },
};

export const customerForm = {
  en: {
    AuthenticationFormTitle: 'Welcome!',
    Subtitle: 'Please enter some information to begin making a deposit towards your Secured Card.',
    CustomerNumber: 'Application ID',
    alphanumeric: {
      CustomerNumberBlank: 'Please enter your 14-16 character application ID',
    },
    numeric: {
      CustomerNumberBlank: 'Please enter your 14-17 digit application ID',
    },
    Received: 'You received this on the confirmation screen when you were approved. Refer to the FAQs below if you can\'t find it.',
    Zip: 'ZIP Code',
    ZipCodeError: 'Please enter a valid 5 digit ZIP code',
    SSN: 'Last 4 Digits of Social Security number',
    SSNInvalid: 'Please enter a valid 4 digits',
    MakeDeposit: 'Make a Deposit',
    FAQ: 'FAQs',
    PhoneDepositMessage: 'If you’d like to make a deposit by phone, you can call 1-800-219-7931.',
  },
  es: {
    AuthenticationFormTitle: '¡Bienvenido!',
    Subtitle: 'Por favor ingresa la siguiente información para comenzar a hacer un depósito a tu Tarjeta con Depósito de Garantía',
    CustomerNumber: 'Número de Identificación de la Solicitud',
    alphanumeric: {
      CustomerNumberBlank: 'Por favor ingresa el número de identificación de tu solicitud de 14 a 16 caracteres',
    },
    numeric: {
      CustomerNumberBlank: 'Por favor ingresa el número de identificación de tu solicitud de 14 a 17 caracteres',
    },
    Received: 'Lo recibiste en la pantalla de confirmación cuando se aprobó tu solicitud. Si no puedes encontrarlo, consulta abajo en Preguntas Frecuentes.',
    Zip: 'Código Postal',
    ZipCodeError: 'Por favor ingresa un código postal de 5 dígitos válido',
    SSN: 'Últimos 4 Dígitos del número de Seguro Social',
    SSNInvalid: 'Por favor ingresa 4 dígitos válidos',
    MakeDeposit: 'Hacer un Depósito',
    FAQ: 'Preguntas Frecuentes',
    PhoneDepositMessage: 'Si deseas hacer un depósito por teléfono, puedes llamar al 1-800-219-7931.',

  },
};

export const faqDialog = {
  en: {
    ModalTitle: 'Deposit Instructions and FAQs',
    content: [
      {
        question: 'How do I make a security deposit?',
        answer: '<p>In order to make a security deposit, you must be able to make an electronic funds transfer through a checking or savings bank account.</p>\n' +
                    '<p>To make a security deposit:</p>\n' +
                    '<ol>\n' +
                    '<li>Complete the fields on the "Welcome" page and click the "Make a Deposit" button.</li>\n' +
                    '<li>Once we validate your information, you will be presented with the "Make a Deposit" page where you can view your deposit history, and, if eligible, make new deposits.</li>\n' +
                    '<li>If you are eligible to make new deposits, you can select the amount you would like to deposit ($20 minimum per transaction) as well as the type of bank account you would like to make the deposit from. Once you complete all fields, click "Next" to proceed.</li>\n' +
                    '<li>The next screen will prompt you to enter your bank account information including the bank routing number and account number.<br /> After completing all fields click the "Next" button.<br /> Note: For security reasons, you will be prompted twice for your account number.<br /> Note: For money market or other accounts, please check with your financial institution to obtain the information you should use for routing and account number.</li>\n' +
                    '<li>Next, you will be prompted to enter your billing information including the name, address, zip, city and state that you use for your bank account. Enter the information and click the "Next" button.</li>\n' +
                    '<li>When prompted, review the terms and conditions and click the "Agree" button.</li>\n' +
                    '<li>Review the deposit summary page and "Submit" your deposit.</li>\n' +
                    '<li>Once your deposit is successfully submitted, you will be presented a confirmation page that confirms the transaction submission and provides you a Confirmation Number. Please store this number and print the confirmation page for your records.</li>\n' +
                    '<li>You may exit the application at this point. Keep in mind that it may take 7-10 business days for the deposit to process.</li>\n' +
                    '</ol>\n' +
                    '<p>&nbsp;</p>',
      },
      {
        question: 'What is an application ID, where do I find it?',
        answer: '<p>Your application ID was provided in your deposit information when you were approved and has been included in our follow-up email communications. It can also be found on your approval letter that should have arrived in the mail 7-10 business days after approval.</p>\n' +
                    '<p>If you have lost or cannot find your application ID you can call 1-800-219-7931 and one of our representatives will be happy to help you make a security deposit.</p>',
      },
      {
        question: 'What happens when I click "Make a Deposit"?',
        answer: 'You will be able to make your security deposit by providing additional information, including a bank routing number, account number and the amount you want to deposit towards your Capital One secured card. All information provided will be protected.',
      },
      {
        question: 'Where do I find my bank routing number and account number?',
        answer: 'The bank routing number and account number can be found at the bottom of bank account checks. Ignore any special characters that are not numbers. The first nine numbers from the left is the routing number (the routing number is always 9 numbers). The next set of numbers is the account number. The third set of numbers is the check number, which you will not need.',
      },
      {
        question: 'How can I make the security deposit for my Capital One Secured Credit Card?',
        answer: 'You can make security deposits online or by calling 1-800-219-7931.',
      },
      {
        question: 'Is it safe to enter and send personal information over the Internet?',
        answer: 'Yes! Capital One is committed to providing secure Internet service through security and encryption technology. Since all of the information passed through this secured site is encrypted, or in other words, encoded, only we can decipher the information you send us, and only you can decipher the information we send you.',
      },
      {
        question: 'How quickly can I get my card?',
        answer: 'Once you\'ve made your full minimum deposit, your card will be mailed within 2 - 3 weeks.',
      },
    ],
  },
  es: {
    ModalTitle: 'Instrucciones para Depósitos y Preguntas Frecuentes',
    content: [
      {
        question: '¿Cómo puedo hacer un depósito de garantía?',
        answer: '<p>Para hacer un dep&oacute;sito de garant&iacute;a, debes poder hacer una transferencia electr&oacute;nica de fondos desde una cuenta bancaria de cheques o de ahorros.</p>\n' +
                    '<p>Para hacer un dep&oacute;sito de garant&iacute;a:</p>\n' +
                    '<ol>\n' +
                    '<li>Llena los campos en la p&aacute;gina "Bienvenido" y haz clic en el bot&oacute;n "Hacer un Dep&oacute;sito".</li>\n' +
                    '<li>Una vez que validemos tu informaci&oacute;n, ver&aacute;s la p&aacute;gina "Hacer un Dep&oacute;sito", donde podr&aacute;s ver tu historial de dep&oacute;sitos y, si calificas, hacer dep&oacute;sitos nuevos.</li>\n' +
                    '<li>Si calificas para hacer dep&oacute;sitos nuevos, puedes seleccionar la cantidad que deseas depositar (m&iacute;nimo de $20 por transacci&oacute;n), as&iacute; como el tipo de cuenta bancaria con el que deseas hacer el dep&oacute;sito. Una vez que llenes todos los campos, haz clic en "Siguiente" para continuar.</li>\n' +
                    '<li>La pantalla siguiente te pedir&aacute; que ingreses la informaci&oacute;n de tu cuenta bancaria, incluidos el n&uacute;mero de ruta del banco y el n&uacute;mero de cuenta.<br /> Una vez que llenes todos los campos, haz clic en el bot&oacute;n "Siguiente".<br /> Nota: Por razones de seguridad, te pediremos el n&uacute;mero de cuenta dos veces.<br /> Nota: Para cuentas de mercado monetario u otras cuentas, por favor verifica con tu instituci&oacute;n financiera para obtener la informaci&oacute;n que debes usar para los n&uacute;meros de ruta del banco y de cuenta.</li>\n' +
                    '<li>A continuaci&oacute;n, se te pedir&aacute; que ingreses tu informaci&oacute;n de facturaci&oacute;n, incluidos el nombre, la direcci&oacute;n, el c&oacute;digo postal, la ciudad y el estado que usas para tu cuenta bancaria. Ingresa la informaci&oacute;n y haz clic en el bot&oacute;n "Siguiente".</li>\n' +
                    '<li>Cuando se te pida, revisa los t&eacute;rminos y condiciones y haz clic en el bot&oacute;n "Aceptar".</li>\n' +
                    '<li>Revisa la p&aacute;gina de resumen del dep&oacute;sito y haz clic en "Enviar" para hacer tu dep&oacute;sito.</li>\n' +
                    '<li>Una vez que tu dep&oacute;sito se env&iacute;e con &eacute;xito, ver&aacute;s una p&aacute;gina de confirmaci&oacute;n que confirma el env&iacute;o de la transacci&oacute;n e incluye un N&uacute;mero de Confirmaci&oacute;n. Por favor guarda este n&uacute;mero e imprime la p&aacute;gina de confirmaci&oacute;n para tus archivos.</li>\n' +
                    '<li>Puedes salir de la solicitud en este momento. Ten presente que el tr&aacute;mite de dep&oacute;sito puede tomar de 7 a 10 d&iacute;as laborales.</li>\n' +
                    '</ol>',
      },
      {
        question: '¿Qué es un número de identificación de solicitud? ¿Dónde puedo encontrarlo?',
        answer: '<p>El número de identificación de tu solicitud figuraba en la información de tu depósito cuando se aprobó tu solicitud y se ha incluido en nuestras comunicaciones por email posteriores. También aparece en la carta de aprobación que debería haber llegado por correo postal entre 7 a 10 días laborables después de la aprobación.</p>\n' +
                    '<p>Si perdiste el número de identificación de tu solicitud o no lo encuentras, puedes llamar al 1-800-219-7931, y uno de nuestros representantes con gusto te ayudará a hacer un depósito de garantía..</p>',
      },
      {
        question: '¿Qué sucede cuando hago clic en "Hacer un Depósito"?',
        answer: 'Podrás hacer tu depósito de garantía al proporcionar información adicional como el número de ruta del banco, el número de cuenta y la cantidad que deseas depositar en tu tarjeta con depósito de garantía con Capital One. Toda información que proporciones será protegida.',
      },
      {
        question: '¿Dónde puedo encontrar mi número de ruta del banco y mi número de cuenta?',
        answer: 'El número de ruta del banco y el número de cuenta aparecen en la parte inferior de los cheques de cuentas bancarias. Ignora los caracteres especiales que no sean números. Los primeros nueve dígitos desde la izquierda son el número de ruta del banco (el número de ruta siempre tiene 9 dígitos). El siguiente grupo de dígitos es el número de cuenta. El tercer grupo de dígitos es el número de cheque, el cual no necesitarás.',
      },

      {
        question: '¿Cómo puedo hacer un depósito en mi Tarjeta con Depósito de Garantía con Capital One?',
        answer: 'Puedes hacer depósitos de garantía en línea o llamando al 1-800-219-7931.',
      },

      {
        question: '¿Es seguro ingresar y enviar información personal por Internet?',
        answer: '¡Sí! Capital One está comprometido a dar un servicio seguro por Internet mediante el uso de tecnología de seguridad y encriptación. Debido a que toda la información que se transmite por este sitio seguro está encriptada, en otras palabras, codificada, solo nosotros podemos descifrar la información que tú nos envías, y solo tú puedes descifrar la información que nosotros te enviamos.',
      },

      {
        question: '¿En cuánto tiempo puedo recibir mi tarjeta?',
        answer: 'Una vez que hagas el depósito mínimo en su totalidad, enviaremos tu tarjeta por correo postal en un plazo de 2 a 3 semanas.',
      },
    ],
  },
};

export const languageDialog = {
  ModalTitle: 'Bienvenido a Capital One',
  ModalTerms: 'Nos complace ofrecer la versión en español de nuestro sitio Web. Aunque hay ciertas opciones disponibles en español, otras páginas, documentos, servicios y comunicaciones solo están disponibles en inglés en este momento. Tenga presente que los servicios en español para productos de financiamiento de vivienda o financiamiento de automóviles son limitados.  Cuando los documentos y las páginas no están disponibles en español, usted debe revisar las versiones en inglés porque pueden tener información Importante sobre la cuenta. ¿Le gustaría continuar en español?',
};

export const depositAmountForm = {
  en: {
    DepositRequired: 'Deposit Required',
    DepositHistory: 'Total Deposits to Date',
    DueBy: 'Deposit Due by',
    AmountToDeposit: 'How much do you want to deposit?',
    DepositCutoff: 'The required security deposit is due by 11:59 PM ET on your deposit due date. If we don\'t receive your full deposit by that date, we won\'t be able to open your account.',
    SliderOkMessage: 'With ',
    SliderOkMessage2: ' in total deposits, your credit line will be ',
    SliderNeedMessage: 'You need to deposit a total of at least ',
    SliderNeedMessage2: ' to get your card with a credit line of $',
    MinimumDeposit: 'Enter the amount you want to deposit',
    AmountToDepositInvalid20: 'Deposit must be at least $20',
    AmountToDepositInvalidMax: 'Your deposit cannot exceed $',
  },
  es: {
    DepositRequired: 'Depósito Requerido',
    DepositHistory: 'Total de Depósitos hasta la Fecha',
    DueBy: 'Fecha Límite para Hacer el Depósito',
    AmountToDeposit: '¿Cuánto deseas depositar?',
    DepositCutoff: 'El depósito de garantía requerido debe realizarse a más tardar a las 11:59 PM, Hora del Este, en la fecha de vencimiento de tu depósito. Si no recibimos tu depósito completo a más tardar en esa fecha, no podremos abrir tu cuenta.',
    SliderOkMessage: 'Con ',
    SliderOkMessage2: ' como total de depósitos, tu línea de crédito será de ',
    SliderNeedMessage: 'Debes depositar un total de al menos ',
    SliderNeedMessage2: ' para obtener tu tarjeta con una línea de crédito de $',
    MinimumDeposit: 'Ingresa la cantidad que deseas depositar',
    AmountToDepositInvalid20: 'El depósito debe ser de al menos $20',
    AmountToDepositInvalidMax: 'Tu depósito no puede sobrepasar $',
  },
};

export const depositHistoryStrings = {
  en: {
    DepositHistoryTitle: 'Deposit History',
    NoDepositHistory: 'Unable to find deposit history. If you think this is an error, please try again later.',
    ConfirmationNumber: 'Confirmation #',
    SourceAccountLastFour: 'Account',
    SubmittedOn: 'Date',
    DepositType: 'Category',
    DepositAmount: 'Amount',
    'DEPOSIT': 'DEPOSIT',
    'DEPOSIT-WEB': 'DEPOSIT-WEB',
    'DEPOSIT-TEL': 'DEPOSIT-TEL',
    'RETURN OF DEPOSIT': 'RETURN OF DEPOSIT',
    'PENDING-WEB': 'PENDING-WEB',
    'PENDING-TEL': 'PENDING-TEL',
  },
  es: {
    DepositHistoryTitle: 'Historial de Depósitos',
    NoDepositHistory: 'No se encontró ningún historial de depósitos.',
    ConfirmationNumber: 'Número de Confirmación',
    SourceAccountLastFour: 'Pagado con la Cuenta',
    SubmittedOn: 'Fecha',
    DepositType: 'Tipo de Depósito',
    DepositAmount: 'Cantidad Depositada',
    'DEPOSIT': 'DEPÓSITO',
    'DEPOSIT-WEB': 'DEPÓSITO-WEB',
    'DEPOSIT-TEL': 'DEPÓSITO-TEL',
    'RETURN OF DEPOSIT': 'DEPÓSITO DEVUELTO',
    'PENDING-WEB': 'PENDIENTE-WEB',
    'PENDING-TEL': 'PENDIENTE-TEL',
  },
};

export const creditLineHelp = {
  en: {
    ModalTitle: 'Deposits & Credit Line',
    Paragraph1: 'Deposit transactions must be at least $20 and in one dollar increments thereafter.',
    Paragraph2a: 'Your minimum security deposit requirement gets you a $',
    Paragraph2b: ' initial credit line. You have until your deposit due date to make deposits.',
    Paragraph3: 'You can also deposit more than the minimum required security deposit to get a higher initial credit line ',
    Paragraph3Bold: 'before you activate your card. ',
    Paragraph3Part2: 'You can increase your initial Credit Line up to $',
    Paragraph4: 'You can make partial deposits to pay your security deposit over time, but need to make sure you\'ve made your full required deposit by the due date. Once we\'ve received your required deposit, your card will be on its way.',
  },
  es: {
    ModalTitle: 'Depósitos y Línea de Crédito',
    Paragraph1: 'Las transacciones de depósito deben ser de al menos $20, con incrementos en dólares enteros a partir de esa cantidad.',
    Paragraph2a: 'Tu requisito mínimo de depósito de garantía te ofrece una línea de crédito inicial de $',
    Paragraph2b: '. Tienes hasta la fecha de vencimiento de tu depósito para hacer depósitos.',
    Paragraph3: 'También puedes depositar una cantidad mayor al requisito mínimo de depósito de garantía para obtener una línea de crédito inicial más alta ',
    Paragraph3Bold: 'antes de que actives tu tarjeta. ',
    Paragraph3Part2: 'Puedes aumentar tu Línea de Crédito inicial hasta $',
    Paragraph4: 'Puede hacer depósitos parciales para pagar su depósito de seguridad a lo largo del tiempo, pero tienes que asegurarte de hacer tu depósito por la cantidad total requerida antes de la fecha de vencimiento. Una vez que recibamos tu depósito requerido, te enviaremos tu tarjeta.',
  },
};

export const depositAccountStrings = {
  en: {
    BankingInformationTitle: 'Bank Account Information',
    BankingInformationSubtitle: 'What\'s your bank account info?',
    HelpMessage: 'You can make a deposit from your personal checking or savings accounts.',
    AccountType: 'Which account do you want to use?',
    AccountTypesChecking: 'Checking',
    AccountTypesSavings: 'Savings',
    RoutingNumber: 'Routing Number',
    AccountNumber: 'Account Number',
    ConfirmAccountNumber: 'Confirm Account Number',
    AccountNumberInvalid: 'Please check your account number',
    InvalidABANumber: 'Sorry, we don\'t recognize this routing number.',
    RoutingNumberInvalid: 'Please enter your 9 digit routing number',
    AccountNumberLengthInvalid: 'Account numbers must be numeric and between 3 and 17 digits.',
  },
  es: {
    BankingInformationTitle: 'Información de Cuenta Bancaria',
    BankingInformationSubtitle: '¿Cuál es la información de tu cuenta bancaria?',
    HelpMessage: 'Puedes hacer un depósito con tu cuenta de cheques o tu cuenta de ahorros personal.',
    AccountType: '¿Qué cuenta deseas usar?',
    AccountTypesChecking: 'Cuenta de Cheques',
    AccountTypesSavings: 'Cuenta de Ahorros',
    RoutingNumber: 'Número de Ruta del Banco',
    AccountNumber: 'Número de Cuenta',
    ConfirmAccountNumber: 'Confirmar Número de Cuenta',
    AccountNumberInvalid: 'Por favor verifica tu número de cuenta',
    InvalidABANumber: 'Lo lamentamos, no reconocemos este número de ruta del banco.',
    RoutingNumberInvalid: 'Por favor ingresa los 9 dígitos de tu número de ruta del banco',
    AccountNumberLengthInvalid: 'Los números de cuenta deben ser numéricos y deben tener entre 3 y 17 dígitos.',
  },
};

export const routingAccountHelpDialog = {
  en: {
    ModalTitle: 'Routing & Account Numbers',
    ModalRouting1: 'Your routing number is the ',
    ModalRoutingGreen: 'first set of numbers ',
    ModalRouting2: 'on the bottom left of your check. ',
    ModalAccount1: 'Your account number is the ',
    ModalAccountBlue: 'second set of numbers',
  },
  es: {
    ModalTitle: 'Números de Ruta del Banco y de Cuenta',
    ModalRouting1: 'Tu número de ruta del banco es el ',
    ModalRoutingGreen: 'primer grupo de dígitos ',
    ModalRouting2: 'que aparece en la parte inferior izquierda de tu cheque. ',
    ModalAccount1: 'Tu número de cuenta es el ',
    ModalAccountBlue: 'segundo grupo de dígitos',
  },
};

export const accountOwnerForm = {
  en: {
    PersonalInformationTitle: 'Account Owner Information',
    PersonalInformationSubtitle: 'Please enter the name and address associated with this bank account.',
    FirstName: 'First Name',
    LastName: 'Last Name',
    StreetAddress: 'Street Address',
    Suite: 'Apt. #',
    SuiteClarification: '(opt.)',
    City: 'City',
    State: 'State',
    SelectState: 'Select State',
    Zip: 'ZIP Code',
  },
  es: {
    PersonalInformationTitle: 'Información del Propietario de la Cuenta',
    PersonalInformationSubtitle: 'Por favor ingresa el nombre y la dirección relacionados con esta cuenta bancaria.',
    FirstName: 'Nombre',
    LastName: 'Apellido',
    StreetAddress: 'Dirección',
    Suite: 'Apto. #',
    SuiteClarification: '(opcional)',
    City: 'Ciudad',
    State: 'Estado',
    SelectState: 'Selecciona un Estado',
    Zip: 'Código Postal',
  },
};

export const tncDialog = {
  en: {
    ModalTitle: 'Terms & Conditions',
    Paragraph1: 'By clicking the "agree" button, I authorize Capital One to initiate an electronic debit to my account in the amount that I have indicated. I understand that this is a one-time authorization and must be completed each time that I wish to make a deposit. By submitting this information, I confirm that I am authorized to access the bank account I provided and acknowledge that the origination of Automatic Clearing House (ACH) transactions to my account must comply with provisions of U.S. law.',
    Paragraph2: 'Capital One will only accept deposits from U.S. banks that participate in the ACH network. Transfers may take several days to complete. Deposits will only be accepted if you have a sufficient balance in the appropriate account to execute the payment order. If you have insufficient funds to cover this amount, your bank may charge you a fee.',
    Paragraph3: 'The minimum deposit amount per authorization is $20 and deposit amounts must be provided in whole dollar increments of $1. Deposits submitted to Capital One through this site are solely for the purpose of adding to your security deposit up to your previously disclosed maximum deposit amount. Deposits submitted through this process will not constitute a payment on your credit card account. All deposits provided are subject to the terms of your Capital One Customer Agreement, which includes your Security Agreement. We do not accept deposits by cash or check. Your card will be sent to you once the deposits you have submitted total the minimum deposit amount required for this product. All deposit amounts are subject to verification.',
    Paragraph4: 'If the information that you submit is incorrect, or your payment is returned due to insufficient funds, we may withdraw this offer and elect not to open a credit card account for you. Should your application close for any reason, we will attempt to return these funds via electronic transfer back to the originating account or by check to the address in the application if an electronic transfer is not possible.',
    Paragraph5: 'Products and services offered by Capital One, N.A.',
  },
  es: {
    ModalTitle: 'Términos y Condiciones',
    Paragraph1: 'Al hacer clic en el botón “aceptar”, autorizo a Capital One a iniciar un débito electrónico en mi cuenta por la cantidad que he indicado. Entiendo que esta es una autorización por una sola vez y que debe completarse cada vez que desee hacer un depósito.  Al enviar esta información, confirmo que estoy autorizado(a) a obtener acceso a la cuenta bancaria que proporcioné y acepto que el origen de las transacciones de la Cámara de Compensación Automatizada (ACH) a mi cuenta debe cumplir con las disposiciones de la ley de los Estados Unidos.',
    Paragraph2: 'Capital One solo aceptará depósitos de bancos de Estados Unidos que participan en la red de la ACH. Es posible que tome varios días completar las transferencias. Los depósitos solo se aceptarán si el saldo que usted tiene en la cuenta correspondiente es suficiente para ejecutar la orden de pago. Si usted tiene fondos insuficientes para cubrir esta cantidad, es posible que su banco le cobre un cargo.',
    Paragraph3: 'La cantidad mínima de depósito por cada autorización es de $20 y las cantidades de los depósitos deben proporcionarse en incrementos de dólares enteros de $1. Los depósitos enviados a Capital One a través de este sitio son solo para poder agregar fondos a su depósito de garantía hasta la cantidad máxima de depósito que se le comunicó anteriormente. Los depósitos enviados mediante este trámite no constituyen un pago a su cuenta de tarjeta de crédito. Todos los depósitos proporcionados están sujetos a los términos de su Acuerdo del Cliente con Capital One, el cual incluye su Acuerdo con Depósito de Garantía.  No aceptamos depósitos en dinero en efectivo ni con cheque. Su tarjeta se le enviará una vez que los depósitos que usted haya enviado alcancen el total de la cantidad mínima de depósito requerida para este producto. Todas las cantidades de depósito están sujetas a verificación.',
    Paragraph4: 'Si la información que usted envía es incorrecta o su pago es devuelto por fondos insuficientes, puede que retiremos esta oferta y optemos por no abrir una cuenta de tarjeta de crédito a su nombre. Si su solicitud se cierra por cualquier razón, intentaremos devolver estos fondos a través de una transferencia electrónica a la cuenta de origen o por cheque a la dirección que aparece en la solicitud si una transferencia electrónica no es posible.',
    Paragraph5: 'Productos y servicios ofrecidos por Capital One, N.A.',
  },
};

export const depositSummaryStrings = {
  en: {
    DepositSummaryTitle: 'Deposit Summary',
    DepositDate: 'Deposit Date',
    RoutingNumber: 'Routing #',
    AccountNumber: 'Account #',
    AccountType: 'Account Type',
    AccountTypesChecking: 'Checking',
    AccountTypesSavings: 'Savings',
  },
  es: {
    DepositSummaryTitle: 'Resumen del Depósito',
    DepositDate: 'Fecha de Depósito',
    RoutingNumber: 'Número de Ruta del Banco',
    AccountNumber: 'Número de Cuenta',
    AccountType: 'Tipo de Cuenta',
    AccountTypesChecking: 'Cuenta de Cheques',
    AccountTypesSavings: 'Cuenta de Ahorros',
  },
};

export const confirmationPageStrings = {
  en: {
    ConfirmationNumber: 'Confirmation Number',
    Congratulations: 'Congratulations!',
    FullyFunded: 'You\'ve made your minimum deposit for your Secured Card. It may take up to 10 business days for your deposit to clear and 2-3 weeks for you to receive your card.',
    AlmostThere: 'You\'re almost there!',
    Remaining: 'Remaining',
    PartiallyFunded: 'Your deposit is being processed. You need to deposit an additional ',
    PartiallyFunded2: ' by ',
    PartiallyFunded3: ' to meet your minimum deposit requirement and receive your card.',
  },
  es: {
    ConfirmationNumber: 'Número de Confirmación',
    Congratulations: '¡Felicidades!',
    FullyFunded: 'Hiciste tu depósito mínimo para tu Tarjeta con Depósito de Garantía. Puede tomar hasta 10 días laborales para que tu pago se registre y de 2 a 3 semanas para que recibas tu tarjeta.',
    AlmostThere: '¡Ya casi terminas!',
    Remaining: 'Restantes',
    PartiallyFunded: 'Se está tramitando tu depósito. Debes hacer un depósito adicional de ',
    PartiallyFunded2: ' antes de ',
    PartiallyFunded3: ' para cumplir con tu requisito de depósito mínimo y recibir tu tarjeta.',
  },
};

export const timeout = {
  en: {
    TimeoutHeader: 'Your session timed out!',
    TimeoutExplanation: 'Thank you for your interest in a Capital One Secured Card! Unfortunately, your session has timed out due to inactivity. Our time-out feature is designed to protect our customers from fraud.',
    VisitHomepage: 'Visit the Capital One homepage',
    WhatNow: 'What Happens Now?',
    DepositSent: 'If you have received a web page stating that your deposit was sent successfully, we have received your deposit. Please allow 7-10 business days for us to process your deposit.',
    DepositNotSent: 'If you have not received a web page stating that your deposit was sent successfully, then we have not received your deposit information. Please return to the login page and re-enter your information. You will be directed to a confirmation page once your deposit is submitted.',
    WarningHeader: 'Session Timeout',
    WarningExpire: 'seconds remaining in session',
    ContinueOrCancel: 'Would you like to continue?',
    NoThanks: 'No, Thanks',
  },
  es: {
    TimeoutHeader: '¡Su sesión ha expirado!',
    TimeoutExplanation: '¡Gracias por su interés en una Tarjeta con Depósito de Garantía de Capital One! Desafortunadamente, su sesión ha expirado por inactividad. Nuestra característica de tiempo de espera está diseñada para proteger a nuestros clientes contra el fraude.',
    VisitHomepage: 'Visite la página principal de Capital One',
    WhatNow: '¿Qué Sucede Ahora?',
    DepositSent: 'Si ha recibido una página web que indique que su depósito se envió con éxito, hemos recibido su depósito. Por favor, espere entre 7 y 10 días laborales para que tramitemos su depósito.',
    DepositNotSent: 'Si no ha recibido una página web que indique que su depósito se envió con éxito, entonces no habremos recibido la información de su depósito. Por favor, regrese a la página de ingreso y vuelva a introducir su información. Se le dirigirá a una página de confirmación una vez se envíe su depósito.',
    WarningHeader: 'Terminación de Sesión',
    WarningExpire: 'segundos restantes en la sesión',
    ContinueOrCancel: '¿Te gustaria continuar?',
    NoThanks: 'No, Gracias',
  },
};
