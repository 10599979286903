import React, { useState, useEffect } from 'react';
import { Divider, Wrapper, Spinner } from '@cof/gravity-react';
import './App.css';
import '@cof/gravity-react/lib/grv-react.min.css'; // import gravity css
import { useIdleTimer } from 'react-idle-timer';

import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';
import ErrorDialog from './components/ErrorDialog';
import TimeoutDialog from './components/TimeoutDialog';
import LanguageDialog from './components/LanguageDialog';
import FAQDialog from './components/FAQDialog';
import DepositCreditLineDialog from './components/DepositCreditLineDialog';
import RoutingAccountDialog from './components/RoutingAccountDialog';

const PROMPT_TIMEOUT = 20;
const SESSION_MAX_TIME_IN_MINUTES = 15;

function App() {
  const isProdURL = (window.location.hostname === 'securedcard.capitalone.com' || window.location.hostname === 'securedcards.capitalone.com');
  const [potomacLoaded, setPotomacLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = isProdURL
      ? '//nexus.ensighten.com/capitalone/Bootstrap.js'
      : '//nexus.ensighten.com/capitalone/dev/Bootstrap.js';
    console.log(`Loading Ensighten scripts from ${script.src}`);

    // Once Ensighten script has fully loaded, mark potomacLoaded state variable as true
    script.addEventListener('load', () => setPotomacLoaded(true));
    document.getElementsByTagName('head')[0].appendChild(script);
  }, [isProdURL]);

  useEffect(() => {
    // This check ensures that tracker is created only after Ensighten script is finished loading
    if (potomacLoaded) {
      const collector = isProdURL
        ? 'potomac-clickstream.capitalone.com'
        : 'potomac-stage.capitalone.com';
      console.log(`Sending Potomac data to ${collector}`);
      window.sp('newTracker', 'capone', collector, {
        appId: 'sspsc',
        discoverRootDomain: true,
        platform: 'web',
        post: true,
      });
    }
  }, [isProdURL, potomacLoaded]);

  const [errorDialogActive, setErrorDialogActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [languageDialogActive, setLanguageDialogActive] = useState(false);
  const [faqDialogActive, setFaqDialogActive] = useState((document.location.pathname === '/faq'));
  const [depositCreditLineDialogActive, setDepositCreditLineDialogActive] = useState(false);
  const [maximumDepositAmount, setMaximumDepositAmount] = useState(1000.0);
  const [minCreditLine, setMinCreditLine] = useState(200.0);
  const [maxCreditLine, setMaxCreditLine] = useState(200.0);
  const [routingAccountDialogActive, setRoutingAccountDialogActive] = useState(false);
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('en');
  const [timeoutDialogActive, setTimeoutDialogActive] = useState(false);
  const [timedOutStatus, setTimedOutStatus] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called 'promptBeforeIdle' milliseconds before 'timeout'
    setSecondsLeft(PROMPT_TIMEOUT);
    setTimeoutDialogActive(true);
  };

  const onIdle = () => {
    // onIdle will be called after the timeout is reached
    setSecondsLeft(0);
    setTimedOutStatus(true);
    setTimeoutDialogActive(false);
  };

  const onActive = () => {
    // onActive will only be called if activate() is called while isPrompted() is true
    setTimeoutDialogActive(false);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout: SESSION_MAX_TIME_IN_MINUTES * 60 * 1000,
    promptBeforeIdle: 20 * 1000,
    stopOnIdle: false,
    onPrompt,
    onIdle,
    onActive,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setSecondsLeft(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  useEffect(() => {
    // ensures that the session will time out even if browser processes
    // are running in the background (e.g. mobile user switches apps / locks their phone)
    if (getRemainingTime() === 0 && timeoutDialogActive) {
      onIdle();
    }
  }, [getRemainingTime, timeoutDialogActive]);

  return (
    <Wrapper className="grv-wrapper">
      <div className="app">
        <Header
          activeLanguage={activeLanguage}
          handleLanguageClick={() => activeLanguage === 'en' ? setLanguageDialogActive(true) : setActiveLanguage('en')}
        />
        <Divider role="presentation" />
        <Body
          activeLanguage={activeLanguage}
          potomacLoaded={potomacLoaded}
          showTimedOutPage={timedOutStatus}
          showErrorDialog={(errorMessage, maxDepAmt) => {
            setMaximumDepositAmount(maxDepAmt);
            setErrorMessage(errorMessage);
            setErrorDialogActive(true);
          }}
          showFaqDialog={() => setFaqDialogActive(true)}
          showDepositCreditLineDialog={(maxCredLine, minCredLine) => {
            setMaxCreditLine(maxCredLine);
            setMinCreditLine(minCredLine);
            setDepositCreditLineDialogActive(true);
          }}
          showRoutingAccountDialog={() => setRoutingAccountDialogActive(true)}
          showSpinner={() => setSpinnerActive(true)}
          hideSpinner={() => setSpinnerActive(false)}
        />
        <Divider role="presentation" size="tiny" />
        <Footer activeLanguage={activeLanguage} />
        <TimeoutDialog
          secondsLeft={secondsLeft}
          activeLanguage={activeLanguage}
          active={timeoutDialogActive}
          onCancel={() => { activate(); }}
          timedOut={() => { setTimeoutDialogActive(false); setTimedOutStatus(true); }}
        />
        <ErrorDialog
          activeLanguage={activeLanguage}
          message={errorMessage}
          active={errorDialogActive}
          depositAmount={maximumDepositAmount}
          onCancel={() => setErrorDialogActive(false)}
        />
        <LanguageDialog
          activeLanguage={activeLanguage}
          active={languageDialogActive}
          onCancel={() => setLanguageDialogActive(false)}
          setLanguage={() => { setActiveLanguage('es'); setLanguageDialogActive(false); }}
        />
        <FAQDialog
          activeLanguage={activeLanguage}
          active={faqDialogActive}
          onCancel={() => setFaqDialogActive(false)}
        />
        <DepositCreditLineDialog
          activeLanguage={activeLanguage}
          active={depositCreditLineDialogActive}
          maxCreditLine={maxCreditLine}
          minCreditLine={minCreditLine}
          onCancel={() => setDepositCreditLineDialogActive(false)}
        />
        <RoutingAccountDialog
          activeLanguage={activeLanguage}
          active={routingAccountDialogActive}
          onCancel={() => setRoutingAccountDialogActive(false)}
        />
        <Spinner active={spinnerActive} fullscreen={true} />
      </div>
    </Wrapper>
  );
}

export default App;
